import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function errorMessage(error: unknown): string {
  if (isRouteErrorResponse(error)) {
    return `${error.status} ${error.statusText}`;
  } else if (error instanceof Error) {
    return error.message;
  } else if (typeof error === 'string') {
    return error;
  } else {
    console.error(error);
    return 'Unknown error';
  }
}

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <Box
      id="error-page"
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Typography variant="overline">
        Oops! Sorry, an error has occurred: {errorMessage(error)}
      </Typography>
    </Box>
  );
}
