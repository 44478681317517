import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#222222',
      },
    },
    typography: {
      button: {
        fontWeight: 400,
      },
    },
  }),
);

export const drawerWidth = 200;
