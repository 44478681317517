import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, Link } from '@mui/material';

export default function SocialFollow() {
  return (
    <Box display="flex" flex="1" justifyContent="center" paddingTop={10}>
      <Box padding={1}>
        <Link href="https://www.instagram.com/yoav.reches/" target="_blank">
          <InstagramIcon />
        </Link>
      </Box>
      <Box padding={1}>
        <Link href="https://www.linkedin.com/in/yoav-reches-4016432b/" target="_blank">
          <LinkedInIcon />
        </Link>
      </Box>
    </Box>
  );
}
