import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import AboutPage from './about-page';
import ErrorPage from './error-page';
import WorkPage from './work-page';
import ProjectPage from './project-page';
import Root from './root';
import { theme } from './theme';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const router = createHashRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <WorkPage /> },
      {
        path: 'about',
        element: <AboutPage />,
      },
      {
        path: 'work/:projectId',
        element: <ProjectPage />,
      },
      {
        path: 'work',
        element: <WorkPage />,
      },
    ],
  },
]);

export default function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </React.StrictMode>
  );
}
