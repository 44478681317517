import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { isTemplateExpression } from 'typescript';

import { tabs } from './data';
import { ProjectType } from './types';

interface Props {
  drawerWidth: number;
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
  projects: ProjectType[];
}

export default function LeftSideNav(props: Props) {
  const { drawerWidth, handleDrawerToggle, mobileOpen, projects } = props;

  const projectsList = (
    <List>
      {projects.map((item, index) => {
        if (!item.isClient) {
          return (
            <ListItem key={item.title} disablePadding>
              <ListItemButton dense={true} component={Link} to={`/work/${index}`}>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          );
        }
      })}
    </List>
  );

  const clientsList = (
    <List>
      {projects.map((item, index) => {
        if (item.isClient) {
          return (
            <ListItem key={item.title} disablePadding>
              <ListItemButton dense={true} component={Link} to={`/work/${index}`}>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          );
        }
      })}
    </List>
  );

  const tabsList = (
    <List>
      {tabs.map((text, index) => (
        <ListItem key={text} disablePadding>
          <ListItemButton dense={true} component={Link} to={`/${text}`}>
            <ListItemText primary={text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: {
          sm: `calc(max(calc((100% - 1200px)/2), 0px) + ${drawerWidth}px)`,
        },
        flexShrink: { sm: 0 },
        zIndex: 1,
      }}
      aria-label="menu items"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            borderWidth: 0,
          },
        }}
      >
        <Toolbar />
        <Box sx={{ m: 2 }} />
        {tabsList}
        <Box sx={{ m: 2 }} />
        {projectsList}
        <Box sx={{ m: 2 }} />
        {clientsList}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            borderWidth: 0,
            marginLeft: `max(calc((100% - 1200px)/2), 0px)`,
          },
        }}
        open
      >
        <Toolbar />
        <Box sx={{ m: 2 }} />
        {projectsList}
        <Box sx={{ m: 2 }} />
        {clientsList}
      </Drawer>
    </Box>
  );
}
