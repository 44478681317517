import { Grid, useMediaQuery } from '@mui/material';
import Container from '@mui/material/Container';
import * as React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

import { imageKitUrl } from './data';
import { GalleryItemType, ProjectType } from './types';
import { getUrl } from './utils';

interface Props {
  projects: ProjectType[];
}

function getGalleryElement(item: GalleryItemType, index: number, isXSmallWindow: boolean) {
  if (item.path.split('.').pop() == 'mp4') {
    return (
      <Grid item xs={isXSmallWindow ? 12 : item.xs} key={item.path}>
        <Link to={`/work/${index}`}>
          <ReactPlayer
            url={getUrl(`${imageKitUrl}/${item.path}`)}
            muted={true}
            loop={true}
            width="100%"
            height="100%"
            playing={true}
            playsinline={true}
            style={{
              cursor: 'pointer',
            }}
          />
        </Link>
      </Grid>
    );
  } else {
    let imgUrl = getUrl(
      `${imageKitUrl}/${item.path}?tr=w-${item.width},h-${item.height},fo-custom`,
    );
    return (
      <Grid item xs={isXSmallWindow ? 12 : item.xs} key={item.path}>
        <Link to={`/work/${index}`}>
          <img
            src={imgUrl}
            srcSet={`${imgUrl}&dpr=2 2x`}
            alt={item.path}
            loading="lazy"
            style={{
              cursor: 'pointer',
              width: '100%',
            }}
          />
        </Link>
      </Grid>
    );
  }
}

function getNonClientGallery(projects: ProjectType[], isXSmallWindow: boolean) {
  return projects.map((item, index) => {
    if (!item.isClient) {
      return item.thumbnails.map((thumbnail) =>
        getGalleryElement(thumbnail, index, isXSmallWindow),
      );
    }
  });
}

function getClientGallery(projects: ProjectType[], isXSmallWindow: boolean) {
  return projects.map((item, index) => {
    if (item.isClient) {
      return item.thumbnails.map((thumbnail) =>
        getGalleryElement(thumbnail, index, isXSmallWindow),
      );
    }
  });
}

export default function WorkPanel(props: Props) {
  const { projects } = props;
  const isXSmallWindow = !useMediaQuery('(min-width:600px)');

  return (
    <Container maxWidth={'lg'}>
      <Grid container spacing={3}>
        {getNonClientGallery(projects, isXSmallWindow)}
      </Grid>
      <Grid container spacing={3} paddingTop={10}>
        {getClientGallery(projects, isXSmallWindow)}
      </Grid>
    </Container>
  );
}
