import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import SocialFollow from './SocialFollow';

const imgLink = `https://ik.imagekit.io/nayo/yoav_handrail_web3.jpg`;

export default function AboutPage() {
  return (
    <Container id="about" maxWidth={'md'}>
      <Box display="flex" flex="1" justifyContent="center">
        <img src={imgLink} srcSet={`${imgLink}?dpr=2 2x`} alt={'handrail'} width={'100%'}></img>
      </Box>
      <Typography variant="h6" paddingTop={3} paddingBottom={3}>
        Bio
      </Typography>
      <Typography paragraph variant="body2">
        Based in Berlin, Germany, Yoav Reches is a designer and maker whose work
        investigates links between technology, user needs, and material culture. His diverse
        portfolio spans product design, furniture, set design, sculpture, and performance,
        which expresses an ongoing interest in expanding both the conceptual and practical
        applications of product design.
      </Typography>
      <Typography paragraph variant="body2">
        In addition to his studio practice, Yoav has overseen the design process for various
        commercial products from their initial concepts to mass manufacturing.
        Between 2011 and 2020, Yoav led <Link href="https://formlabs.com" color="inherit" underline="hover">Formlabs</Link>' industrial design team.
        His contributions enabled the successful launch of multiple generations of desktop SLA/SLS 3D printing systems
        and was honored with the prestigious Red Dot Awards.
      </Typography>
      <Typography paragraph variant="body2">
        Yoav's creative works have been widely exhibited internationally at venues such as the Milan
        Furniture Fair, London Design Week, Volker Diehl Gallery in Berlin, La Manufacture National
        de Sèvres Gallery in Paris, The Third Biennale for Ceramics in Tel Aviv, Design Museum
        Holon, and the Mingei International Museum in San Francisco.
      </Typography>
      <SocialFollow />
    </Container>
  );
}
