import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Outlet, ScrollRestoration, useParams } from 'react-router-dom';

import _data from './info.json';
import LeftSideNav from './LeftSideNav';
import { drawerWidth } from './theme';
import TopNav from './TopNav';
import { DataType } from './types';

const data = _data as DataType;

export type MyParams = {
  projectId: string;
};

export default function Root() {
  const { projectId } = useParams<MyParams>();

  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  //const [data, setData] = useState<DataType>({ projects: [], clients: [] });

  useEffect(() => {
    //const dataFetch = async () => {
    //  const data = await fetch(s3Url).then((res) => res.json());
    //  setData(data);
    //};
    //dataFetch();

    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const isProjectTab = projectId !== undefined;
  const isXSmallWindow = !useMediaQuery('(min-width:600px)');

  return (
    <>
      <ScrollRestoration />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <TopNav drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
        {(isProjectTab || isXSmallWindow) && (
          <LeftSideNav
            drawerWidth={drawerWidth}
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileDrawerOpen}
            projects={data.projects}
          />
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <Toolbar />
          <div id="detail">
            <Outlet />
          </div>
        </Box>
      </Box>
    </>
  );
}
