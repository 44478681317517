import _data from './info.json';
import SocialFollow from './SocialFollow';
import { DataType } from './types';
import WorkPanel from './WorkPanel';

const data = _data as DataType;

export default function WorkPage() {
  return (
    <>
      <WorkPanel projects={data.projects} />;
      <SocialFollow />
    </>
  );
}
