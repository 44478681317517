import { useParams } from 'react-router-dom';

import _data from './info.json';
import ProjectPanel from './ProjectPanel';
import { DataType } from './types';
import { MyParams } from './root';

const data = _data as DataType;

export default function ProjectPage() {
  const { projectId } = useParams<MyParams>();
  return <ProjectPanel data={data} projectId={parseInt(projectId ?? '0')} />;
}
