import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { tabs, title } from './data';
import logo from './logo.png';

interface Props {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}

export default function TopNav(props: Props) {
  const { drawerWidth, handleDrawerToggle } = props;

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: '#ffffffe0',
        zIndex: 2,
      }}
      style={{ borderBottom: '0.01em solid lightgray' }}
    >
      <Toolbar>
        <Box component="img" sx={{ height: 48 }} alt="Logo" src={logo} />
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            color: '#222',
            fontWeight: 400,
          }}
        >
          {title}
        </Typography>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Button key={'work'} sx={{ color: '#000' }} component={Link} to={'/work'}>
            Work
          </Button>
          <Button key={'about'} sx={{ color: '#000' }} component={Link} to={'/about'}>
            About
          </Button>
        </Box>
        <IconButton
          color="primary"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
