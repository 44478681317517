import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Stack, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import _ from 'lodash';
import * as React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

import { imageKitUrl } from './data';
import { drawerWidth } from './theme';
import { DataType } from './types';
import { getUrl } from './utils';

interface Props {
  data: DataType;
  projectId: number;
}

export default function ProjectPanel(props: Props) {
  const { data, projectId } = props;
  const projectTitles = data.projects.map((item) => item.title);
  const selectedProject = data.projects[projectId];
  const isMdWindow = useMediaQuery('(min-width:900px)');
  const isSmWindow = useMediaQuery('(min-width:600px)');

  function getNext() {
    const nextIndex = projectId + 1;
    return nextIndex < projectTitles.length ? nextIndex : 0;
  }

  function getPrev() {
    const prevIndex = projectId - 1;
    return prevIndex < 0 ? projectTitles.length - 1 : prevIndex;
  }

  function getFeaturedImages() {
    return (
      <ImageList variant="standard" sx={{ width: '100%', overflow: 'hidden' }} cols={1} gap={20}>
        {selectedProject.featuredImages.map((item) => {
          const imgLink = getUrl(
            `${imageKitUrl}/${selectedProject.title}/${item}?tr=w-1200,fo-auto`,
          );
          return (
            <ImageListItem key={imgLink + item}>
              <img src={imgLink} srcSet={`${imgLink}dpr=2 2x`} alt={item} loading="lazy" />
            </ImageListItem>
          );
        })}
      </ImageList>
    );
  }

  function getImages() {
    return (
      <ImageList
        variant="masonry"
        sx={{ width: '100%', overflow: 'hidden' }}
        cols={isMdWindow ? 2 : 1}
        gap={20}
      >
        {selectedProject.images.map((item) => {
          const imgLink = getUrl(
            `${imageKitUrl}/${selectedProject.title}/${item}?tr=w-600,fo-auto`,
          );
          return (
            <ImageListItem key={imgLink + item}>
              <img src={imgLink} srcSet={`${imgLink}dpr=2 2x`} alt={item} loading="lazy" />
            </ImageListItem>
          );
        })}
      </ImageList>
    );
  }

  function getNavigation() {
    return (
      <Grid container justifyContent="space-between" spacing={2} paddingTop={3}>
        <Grid>
          <Button variant="text" component={Link} to={`/`}>
            Back to Work
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="text"
            startIcon={<ArrowBackIosNewIcon />}
            component={Link}
            to={`/work/${getPrev()}`}
          >
            Previous
          </Button>
          <Button
            variant="text"
            endIcon={<ArrowForwardIosIcon />}
            component={Link}
            to={`/work/${getNext()}`}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    );
  }

  function getText() {
    return (
      <Stack spacing={2}>
        <Typography variant="h4">{selectedProject.title}</Typography>
        {selectedProject.subtitle && (
          <Typography variant="subtitle2">{selectedProject.subtitle}</Typography>
        )}
        {selectedProject.year && (
          <Typography variant="subtitle2">{selectedProject.year}</Typography>
        )}
        {selectedProject.description && (
          <Typography variant="body2">{selectedProject.description}</Typography>
        )}
        {selectedProject.materialDescription && (
          <Typography variant="body2">{selectedProject.materialDescription}</Typography>
        )}
      </Stack>
    );
  }

  function isUrl(path: string | undefined) {
    return path !== undefined && path.startsWith('http');
  }

  function getBanner() {
    const contentPath = selectedProject.bannerVideo ?? selectedProject.bannerImage;
    if (!contentPath) {
      return;
    }
    const bannerAspectRatio = selectedProject.bannerAspectRatio ?? 0.5625;
    const bannerHeight = isSmWindow
      ? Math.min(window.innerWidth - drawerWidth - 48, 975) * bannerAspectRatio
      : window.innerWidth * bannerAspectRatio;
    const contentUrl = isUrl(contentPath)
      ? contentPath
      : getUrl(`${imageKitUrl}/${selectedProject.title}/${contentPath}`);

    return (
      <div style={{ width: '100%', height: bannerHeight }}>
        {selectedProject.bannerVideo && (
          <ReactPlayer
            url={contentUrl}
            muted={true}
            loop={true}
            width="100%"
            height="100%"
            playing={true}
            playsinline={true}
          />
        )}
        {selectedProject.bannerImage && <img width={'100%'} src={contentUrl} />}
      </div>
    );
  }

  return (
    <Box maxWidth={'975px'}>
      {getText()}
      {getFeaturedImages()}
      {getBanner()}
      {getImages()}
      {getNavigation()}
    </Box>
  );
}
